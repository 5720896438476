
/**
 * @name: 商品规格
 * @author: itmobai
 * @date: 2023-05-31 15:25
 * @description：商品规格
 * @update: 2023-05-31 15:25
 */
import {Component, Vue} from "vue-property-decorator";
import {ICrudOption} from "@/types/m-ui-crud";
import {ISpecification, ISpecificationItem, ISpecificationParam} from "@/apis/product/specification/types";
import {
  changeProductSpecsListApi,
  productspecsQueryApi, productspecsQueryProductspecsListApi,
  productspecsvalueCreateApi,
  productspecsvalueModifyApi, productspecsvalueRemoveApi
} from "@/apis/product/specification";

@Component({})
export default class SpecificationPage extends Vue {
  // 表格加载状态
  tableLoading: boolean = false;
  // 表格总数
  tableTotal: number = 0;
  // 表格数据
  tableData: ISpecification[] = []
  // 表单
  modelForm: Partial<ISpecification> = {}
  // 查询参数
  queryParam: ISpecificationParam = {
    specsNameKeywords: ""
  }
  // 属性值弹窗
  dialogVisible: boolean = false;
  // 当前规格id
  selectId: string = ""
  // crudOption
  option: ICrudOption = {
    addTitle: '新增商品规格',
    editTitle: '编辑商品规格',
    dialogWidth: '600px',
    searchBox: false,
    column: [
      {
        "label": "ID",
        "prop": "id",
        "align": "left",
        "width": "180",
        addHide: true,
        editHide: true
      },
      // {
      //   "label": "规格名称",
      //   "prop": "specsNameKeywords",
      //   "search": true,
      //   "hide": true,
      //   addHide: true,
      //   editHide: true
      // },
      {
        "label": "规格名称",
        "prop": "specsName",
        "align": "center",
        "overHidden": true,
        maxlength: 20,
        rules: [
          {required: true, message: "请输入规格名称", trigger: 'blur'}
        ]
      },
      {
        "label": "属性值",
        "prop": "productSpecsName",
        "align": "center",
        "overHidden": true,
        addHide: true,
        editHide: true
      },
      {
        "label": "状态",
        "prop": "status",
        "type": "switch",
        "align": "center",
        "slot": true,
        "dicData": [
          {
            label: "禁用",
            value: 2
          },
          {
            label: "启用",
            value: 1
          }
        ],
        rules: [
          {required: true, message: "请选择状态", trigger: 'blur'}
        ]
      },
    ]
  }
  // 属性列表
  rows: {name: string, id: string}[] = []

  dialogName: string = "";

  rowDel (form: ISpecification, index: number) {
    productspecsvalueRemoveApi(form.id).then(e => {
      if (e) {
        this.$message.success("操作成功!")
        this.getList()
      }
    })
  }

  rowSave (form: ISpecification, done: Function, loading: Function) {
    productspecsvalueCreateApi(form).then(e => {
      if (e) {
        done()
        this.$message.success("操作成功!")
        this.getList()
      }
    }).finally(() => {
      loading()
    })
  }

  addSpecificationRow () {
    if (!this.dialogName) {
      return
    }
    this.rows.push({
      id: '',
      name: this.dialogName
    })
    this.dialogName = ""
  }

  rowEdit (form: ISpecification, done: Function, loading: Function) {
    productspecsvalueModifyApi(form).then(e => {
      if (e) {
        done()
        this.$message.success("操作成功!")
        this.getList()
      }
    }).finally(() => {
      loading()
    })
  }

  openEdit (row: ISpecification, index: number) {
    // @ts-ignore
    this.$refs.crudRef.rowEdit(row, index);
  }

  openDialog (row: ISpecification) {
    this.selectId = row.id;
    this.dialogName = "";
    // @ts-ignore
    productspecsQueryProductspecsListApi(row.id).then(e => {
      this.rows = e.map((item: any) => {
        return {
          id: item.id,
          name: item.name
        }
      })
      this.dialogVisible = true
    })
  }

  /**
   * 属性值修改/创建
   */
  enterDialog () {
    changeProductSpecsListApi({id: this.selectId, productSpecsDtoList: this.rows}).then(e => {
      if (e) {
        this.$message.success("操作成功!")
        this.dialogVisible = false;
        this.getList()
      }
    })
  }

  getList () {
    this.tableLoading = true
    productspecsQueryApi(this.queryParam).then(e => {
      this.tableLoading = false
      this.tableData = e;
    })
  }

  /**
   * 切换状态
   * @param id id
   * @param status 状态
   */
  handleSwitch (id: string, status: number) {
    productspecsvalueModifyApi({id, status} as ISpecification).then(e => {
      if (e) {
        this.$message.success("操作成功!")
        this.getList()
      }
    })
  }

  created () {
    this.getList()
  }
}
