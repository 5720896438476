/**
 * @name: 商品规格接口
 * @author: itmobai
 * @date: 2023-05-31 15:22
 * @description：商品规格接口
 * @update: 2023-05-31 15:22
 */
import {get, postJ} from "@/request";
import {ISpecification, ISpecificationParam} from "@/apis/product/specification/types";

/**
 * 商品规格查询
 * @param param 查询参数
 */
export const productspecsQueryApi = (param: ISpecificationParam) => get("/golf/productspecsvalue/queryByList", param)
/**
 * 商品规格创建
 * @param data
 */
export const productspecsvalueCreateApi = (data: ISpecification) => postJ("/golf/productspecsvalue/create", data)
/**
 * 商品规格修改
 * @param data
 */
export const productspecsvalueModifyApi = (data: ISpecification) => postJ("/golf/productspecsvalue/modify", data)
/**
 * 商品规格删除
 * @param id
 */
export const productspecsvalueRemoveApi = (id: string) => get("/golf/productspecsvalue/remove/" + id)
/**
 * 商品规格属性查询
 * @param specsValueId 规格id
 */
export const productspecsQueryProductspecsListApi = (specsValueId: string) => get("/golf/productspecs/queryProductspecsList", {specsValueId})
/**
 * 商品规格属性创建/修改
 * @param data
 */
export const changeProductSpecsListApi = (data: any) => postJ("/golf/productspecsvalue/changeProductSpecsList", data)
